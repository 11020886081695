import React from 'react';
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../../utilities/IntlMessages';
import InfoView from '../../components/InfoView';
import { authenticateUser } from '../../../store/actions/Auth.action'
import config from "../../../config";

class Login extends React.Component {
  constructor(props) {
    super();
    this.state = {
      email: '',
      password: '',
	  error:''
    }
	 const query = new URLSearchParams(props.location.search);
    this.empcode = query.get('empcode')										 
  }
  componentDidMount() {
    if (this.empcode)
      this.props.authenticateUser({ empcode: this.empcode });
  }
  componentDidUpdate() {
    if (this.props.token !== null) {
      this.props.history.push('/');
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.error && props.error != '') {
      return { error: props.error }
    }
    return null;
  }

  render() {
    if (this.props.token !== null) {
      return (<Redirect to="/app/addressable-market-list" />);
  
    }
    const {
      email,
      password
    } = this.state;
    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
		 {config.loginThroughPassword == 'no' ? this.empcode ?
                <div className="app-login-main-content" style={{"padding":"51px 140px 51px 140px"}}>
                <h2 style={{ color: 'red' }}>{this.state.error}</h2></div>:
                <div className="app-login-main-content" style={{"padding":"51px 120px"}}> <h1>Kindly login through the Software Links.</h1></div> :
                null
          }
          {config.loginThroughPassword == 'no' ?null
                // <img src={LockImage} className="imp-responsive" style={{ width: "30%" }} />
            :
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center" style={{ backgroundImage: "url(" + require("../../../assets/images/logo.jpg") + ")" }}>
          </div>
          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h3><IntlMessages id="appModule.signInto" /> <b>Marketing Database</b></h3>
            </div>

            <div className="app-login-form">
              <form>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="appModule.email" />}
                    fullWidth
                    onChange={(event) => this.setState({ email: event.target.value })}
                    defaultValue={email}
                    margin="normal"
                    className="mt-1 my-sm-3 fb-login-text-field"
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.password" />}
                    fullWidth
                    onChange={(event) => this.setState({ password: event.target.value })}
                    defaultValue={password}
                    margin="normal"
                    className="mt-1 my-sm-3 fb-login-text-field"
                    onKeyPress={(ev) => {
                      if (ev.key === 'Enter') {
                        this.props.authenticateUser(this.state);
                      }
                    }}
                  />
                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button onClick={() => {
                      this.props.authenticateUser(this.state);
                    }} variant="contained" className="bg-fb text-white">
                      <IntlMessages id="appModule.signIn" />
                    </Button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
		     }
        {this.empcode?null:<InfoView />}
      </div>
    );
  }
}

const mapStateToProps = ({ auth,commonData }) => {
  const { authUser, token } = auth;
  const { error } = commonData;
  return { authUser, token ,error }
};

export default connect(mapStateToProps, { authenticateUser })(Login);
