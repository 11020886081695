import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {allRoute} from '../helper/route';
const RouteComponent = () => {
    return (
        <Switch>
            {
                allRoute.map((routes,index) => (
                    <Route key={index} path={routes.path}
                        component={routes.component} />
                ))
            }
        </Switch>
    );
}
export default RouteComponent;