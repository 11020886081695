import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CustomScrollbars from "../../../utilities/CustomScrollbars";
import { routeNavigation } from "../../helper/route";
import { connect } from "react-redux";
import Navigation from "../../hoc/Navigation";
class SidenavContent extends Component {
  constructor(props) {
    super();
    this.userRoute = routeNavigation;
    const ExistModule = ['Unsubscribe List','Suppression List']
    const accessed_modules = JSON.parse(localStorage.getItem("module_access"));  
    
   
    console.log("1==>",accessed_modules)
    accessed_modules.reverse().map(ele=>{
      if(ele.module_name ==='Mautic Report')
        ele.module_name = 'Mautic Reports'
      
      if(ele.module_name ==='Addressable Market Reports')
        ele.module_name = 'Addressable Market'      
    })  
    accessed_modules.push({module_name:"Mautic Reports",interfaceName:"Labs2 Cognition Solutions",actionName:"View",parent_id:"66c83ce2456b4ac604c4b6e5"})
   // accessed_modules.push({module_name:"Mautic Reports",parent_id:0})    
    // if (accessed_modules !== null && accessed_modules !== '' && typeof(accessed_modules)!=="undefined" && accessed_modules.length>0 ) {
    //   var accessed_routes = [ {
    //     parent: "Dashboard",
    //     parentIcon: 'zmdi zmdi-tv-list zmdi-hc-fw',
    //     path: '/app/dashboard'
    //   }];
    //     accessed_modules.map(function (acc) {
    //        routeNavigation.map(function (rt) {
    //          if (acc.parent_id === 0 && acc.module_name === rt.parent) {
    //            accessed_routes.push(rt);
    //          } else {
    //            if (typeof rt.childNav !== "undefined") {
    //              var childNav = rt.childNav.filter(function (object) { return object['child'] === acc.module_name });
    //              if (childNav.length > 0) {
    //                accessed_routes.push({ parent: rt.parent, parentIcon: rt.parentIcon, childNav: childNav });
    //              }
    //            }
    //          }
    //        })
    //      })
    //   this.userRoute = accessed_routes;
    // }

    if (accessed_modules !== null && accessed_modules !== '' && typeof(accessed_modules)!=="undefined" && accessed_modules.length>0 ) {
      var accessed_routes = [ {
        parent: "Dashboard",
        parentIcon: 'zmdi zmdi-tv-list zmdi-hc-fw',
        path: '/app/addressable-market-list'
      }];
       let tempModuel =[]
        accessed_modules.map(function (acc) {
           routeNavigation.map(function (rt) {                   
             if (acc.module_name.trim() === rt.parent  && tempModuel.indexOf(rt.parent) === -1 && acc.actionName === 'View') {                      
               accessed_routes.push(rt);
               tempModuel.push(rt.parent)
             } else {
               if (typeof rt.childNav !== "undefined") {
                 var childNav = rt.childNav.filter(function (object) { return (object['child'] === acc.module_name && acc.actionName === 'View')});
                 if (childNav.length > 0 ) {
                   accessed_routes.push({ parent: rt.parent, parentIcon: rt.parentIcon, childNav: childNav });
                 }
               }
             }
           })
         })
        //  accessed_routes.push({parent: 'Mautic Reports', parentIcon: 'zmdi zmdi-chart zmdi-hc-fw', childNav:[
        //   {child: 'Labs2 Cognition Solutions', path: '/app/reports/mautic-campaign-report/4'}]})    
        console.log("2==>",this.userRoute) 
       let menuData = accessed_routes 
       let interfaceDataData = accessed_modules     
        if(menuData.length >0){
          for(let i=0;i<menuData.length;i++){        
           if(typeof menuData[i].childNav !== "undefined"){
            let citems=[]
            let childNavData = menuData[i].childNav;           
            for(let j=0;j<childNavData.length;j++){
              let childData = Object.assign({}, childNavData[j]);
              for(let k=0;k<interfaceDataData.length;k++){                
                if(interfaceDataData[k].interfaceName === childData.child){                  
                  citems.push(childData)
                 }
                }
            }
            menuData[i].childNav = citems
           } 
          }
        }

      this.userRoute = menuData
      console.log("2==>",this.userRoute)
    }
  }

  
  
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`;// get current path
    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {

        const parentLiEle = that.closest(this, "li");
        if (menuLi[i].classList.contains("menu") && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains("open")) {
            menuLi[i].classList.remove("open", "active");
          } else {
            menuLi[i].classList.add("open", "active");
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, "li");
            if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains("open"))) {
              menuLi[j].classList.remove("open");
            } else {
              if (menuLi[j].classList.contains("open")) {
                menuLi[j].classList.remove("open");
              } else {
                menuLi[j].classList.add("open");
              }
            }
          }
        }
      }
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`;// get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
        if (typeof document.body[fn] === "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {

    }

    return null;
  }
  render() {
    return (
      <CustomScrollbars className="fb-side-nav">
        <ul className="nav-menu">
          {
            this.userRoute.map((routeNav, index) => (
              <Navigation key={index} routeNav={routeNav} handleSideDrawer={this.props.handleSideDrawer}/>
            ))
          }

        </ul>
      </CustomScrollbars>
    );
  }
}
const mapStateToProps = ({ auth }) => {
  const { route } = auth;
  return { route:route };
}
export default withRouter(connect(mapStateToProps)(SidenavContent)); 